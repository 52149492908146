.privilege-list {
  width: 100%;
  height: 100%;
}

.privilege-list .card-table {
  display: inline-block;
  justify-content: space-around;
  width: 100%;
  min-height: 180px;
  padding: 16px;
  background: #FFFFFF;
  transition: all .3s;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  border-radius: 4px;
}

.privilege-list .input-filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 32px;
}

.privilege-list .input-filter .input-item {
  width: 236px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 16px;
  padding-bottom: 16px;
}

.privilege-list .input-filter .input-item.button {
  width: 300px;
}

.privilege-list .input-filter .input-item.short {
  width: 180px;
} 

.privilege-list .input-filter .input-item .text {
  flex: 1;
  font-weight: bold;
}

.privilege-list .input-filter .input-item .btn{
  margin-right: 16px;
  display: inline-block;
}

.privilege-list .input-filter .input-item .btn .ant-btn{
  margin-right: 16px;
}

.privilege-list .export-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;
}
.privilege-list .export-bar .create .ant-btn {
  margin-right: 16px;
}

.privilege-list .export-bar .create .ant-btn:first-child {
  color: rgb(12, 117, 56);
}

.btn-row {
  width: 280px;
}

.btn-row .ant-btn {
  margin-right: 8px;
}

@media only screen and (max-width: 1300px){
  .privilege-list .input-filter .input-item {
    width: 32%;
  }
  .privilege-list .input-filter .input-item.short {
    width: 24%;
  }
  .privilege-list .input-filter .input-item.button {
    width: 40%;
  }
}

@media only screen and (max-width: 992px){
  .privilege-list .input-filter {
    margin-left: 28px;
  }
}

@media only screen and (max-width: 729px) {
  .privilege-list .input-filter {
    display: inline-block;
  }
  .privilege-list .input-filter .input-item, .privilege-list .input-filter .input-item.short {
    width: 90%;
    margin:  0;
  }

  .privilege-list .input-filter .input-item.button {
    width: 90%;
  }

  .privilege-list .input-filter .input-item .btn{
    margin: 0;
    margin-right: 16px;
  }

  .privilege-list .card-table {
    display: inline-block;
    overflow-x: auto;
  }
  .ant-table-wrapper {
    margin-right: 2.5%;
  }
}
