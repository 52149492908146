.linecc-list {
  width: 100%;
  height: 100%;
}

.linecc-list .card-table {
  display: inline-block;
  justify-content: space-around;
  width: 100%;
  min-height: 180px;
  padding: 16px;
  background: #FFFFFF;
  transition: all .3s;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  border-radius: 4px;
}

@media only screen and (max-width: 729px) {
  .linecc-list .card-table {
    display: inline-block;
    overflow-x: auto;
  }
}