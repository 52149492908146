.dashboard {
  width: 100%;
  height: 100%;
}

.dashboard .row, .dashboard .row-chart {
  display: flex;
}

.dashboard .row .card {
  display: flex;
  justify-content: space-around;
  min-width: 300px;
  min-height: 180px;
  margin-right: 16px;
  padding: 16px;
  background: #FFFFFF;
  transition: all .3s;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  border-radius: 4px;
}

.dashboard .row .card .card-col {
  display: flex;
  align-items: center;
  height: 100%;
}

.dashboard .row .card .card-col i{
  font-size: 4em;
  font-weight: bold;
}

.dashboard .row .card .card-col p{
  font-size: 1.5em;
  font-weight: bold;
}

.dashboard .row-chart .chart-item {
  min-width: 45%;
  margin-right: 16px;
  margin-top: 16px;
  transition: all .3s;
  /* background: #5555AA; */
  
}

.dashboard .row-chart .chart-item .chart {
  min-height: 400px;
  background: #FFFFFF;
  padding: 24px;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  border-radius: 4px;
}

.dashboard .row-chart .chart-item .search {
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  width: 450px;
}

/* .dashboard .card:hover, .dashboard .chart:hover {
  box-shadow: 4px 4px 40px rgba(0,0,0,.05);
} */
@media only screen and (max-width: 1170px) {
  .dashboard .row-chart .chart-item .search {
    display: flex;
    width: 100%;
  }

  .dashboard .row-chart .chart-item .search .input-item {
   width: 70%; 
  }
}

@media only screen and (max-width: 1030px) {
  .dashboard .row-chart {
    width: 100%;
    display: inline-block;
  }
  .dashboard .row-chart .chart-item {
    width: 100%;
    margin: 10px 0;
    padding: 0;
  }
}

@media only screen and (max-width: 647px) {
  .dashboard .row {
    width: 100%;
    display: inline-block;
  }
  .dashboard .row .card {
    width: 100%;
    margin: 10px 0;
  }
  .dashboard .row .card .card-col {
    display: flex;
    align-items: center;
    height: 100%;
    margin-top: 20px;
  }

  .dashboard .row-chart .chart-item .search {
    display: flex;
    width: 100%;
  }

  .dashboard .row-chart .chart-item .search .input-item {
   width: 70%; 
  }
}
