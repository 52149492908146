.tracking-url-form {
  display: inline-block;
  width: 100%;
  min-height: 180px;
  padding: 16px;
  background: #ffffff;
  transition: all 0.3s;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  border-radius: 4px;
}

.tracking-url-form .form {
  max-width: 650px;
}
