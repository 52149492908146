.maintenance .card-table {
    display: inline-block;
    justify-content: space-around;
    width: 100%;
    min-height: 180px;
    padding: 16px;
    background: #FFFFFF;
    transition: all .3s;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    border-radius: 4px;
}

@media only screen and (max-width: 600px) {
    .maintenance .card-table {
      display: inline-block;
      overflow-x: auto;
    }
    .ant-table-wrapper {
      margin-right: 2.5%;
    }
}