.carousel-list {
  width: 100%;
  height: 100%;
}

.carousel .card-table {
  display: inline-block;
  justify-content: space-around;
  width: 100%;
  min-height: 180px;
  padding: 16px;
  margin-bottom: 16px;
  background: #FFFFFF;
  transition: all .3s;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  border-radius: 4px;
}

.carousel .card-table .save {
  padding-top: 16px;
  float: right;
}

.carousel .card-table .save .ant-btn{
  margin-left: 8px;
}

.carousel .card-table .search {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 8px;
}

.carousel .card-table .search .ant-btn{
  margin-right: 8px;
}

.carousel .card-table .search .ant-input{
  width: 300px;
  margin-right: 8px;
}

.carousel .card-table .ant-table .minus{
  color: red;
  font-size: 1.8em;
  font-weight: bold;
}

.carousel .card-table .ant-table .plus{
  color: #009977;
  font-size: 1.8em;
  font-weight: bold;
}

@media only screen and (max-width: 729px) {
  .carousel .card-table {
    display: inline-block;
    overflow-x: auto;
  }
}