.customer {
  width: 100%;
  height: 100%;
}

.customer .card-table {
  display: inline-block;
  justify-content: space-around;
  width: 100%;
  min-height: 180px;
  padding: 16px;
  background: #FFFFFF;
  transition: all .3s;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  border-radius: 4px;
}

.customer .input-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 32px;
}

.customer .input-filter .input-item {
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 16px;
}

.customer .input-filter .input-item.short {
  width: 180px;
}

.customer .input-filter .input-item.button {
  width: 300px;
}

.customer .input-filter .input-item .text {
  flex: 1;
  font-weight: bold;
}

.customer .input-filter .input-item .btn{
  margin-right: 16px;
  display: inline-block;
}

.customer .input-filter .input-item .btn .ant-btn{
  margin-right: 16px;
}

.customer .export {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 4px;
}

@media only screen and (max-width: 1279px){
  .customer .input-filter .input-item {
    width: 32%;
  }
}

@media only screen and (max-width: 1020px){
  .customer .input-filter .input-item.button {
    width: 40%;
  }
}

@media only screen and (max-width: 992px){
  .customer .input-filter {
    margin-left: 28px;
  }
}

@media only screen and (max-width: 729px) {
  .customer .input-filter {
    display: inline-block;
  }
  .customer .input-filter .input-item {
    width: 90%;
    margin: 16px 0;
  }

  .customer .input-filter .input-item.short {
    width: 90%;
  }

  .customer .input-filter .input-item.button {
    width: 90%;
  }

  .customer .input-filter .input-item .btn{
    margin: 0;
    margin-right: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .customer .card-table {
    display: inline-block;
    overflow-x: auto;
    
  }
  .ant-table-wrapper {
    margin-right: 2.5%;
  }
}
