#components-form-login {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  align-items: stretch;
  width: 380px;
  height: 400px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

#components-form-login .logo-login {
  background: rgba(255, 255, 255, 0.3);
  background-image: url(./MTL_LOGO.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  width: 368px;
  height: 140px;
  margin-bottom: 24px;
}

#components-form-login .login-form {
  max-width: 100%;
  height: 200px;
}

#components-form-login .login-form-button {
  width: 100%;
}

.err-message {
  color: red;
}

@media only screen and (max-width: 600px) {
  #components-form-login {
    box-shadow: none;

  }
}
