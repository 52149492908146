.agent-backlist-monthly {
    width: 100%;
    height: 100%;
  }
  
  .agent-backlist-monthly .card-table {
    display: inline-block;
    justify-content: space-around;
    width: 100%;
    min-height: 180px;
    padding: 16px;
    background: #FFFFFF;
    transition: all .3s;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    border-radius: 4px;
  }
  
  .agent-backlist-monthly .input-filter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 16px;
  }
  
  .agent-backlist-monthly .input-filter .input-item {
    width: 236px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-right: 16px;
    padding-bottom: 16px;
  }
  
  .agent-backlist-monthly .input-filter .input-item.button {
    width: 300px;
  }
  
  .agent-backlist-monthly .input-filter .input-item.short {
    width: 180px;
  } 
  
  .agent-backlist-monthly .input-filter .input-item .text {
    flex: 1;
    font-weight: bold;
  }
  
  .agent-backlist-monthly .input-filter .input-item .btn{
    margin-right: 16px;
    display: inline-block;
  }
  
  .agent-backlist-monthly .input-filter .input-item .btn .ant-btn{
    margin-right: 16px;
  }
  
  .agent-backlist-monthly .export {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 4px;
  }
  
  @media only screen and (max-width: 1300px){
    .agent-backlist-monthly .input-filter .input-item {
      width: 32%;
    }
    .agent-backlist-monthly .input-filter .input-item.short {
      width: 24%;
    }
    .agent-backlist-monthly .input-filter .input-item.button {
      width: 40%;
    }
  }
  
  @media only screen and (max-width: 992px){
    .agent-backlist-monthly .input-filter {
      margin-left: 28px;
    }
  }
  
  @media only screen and (max-width: 729px) {
    .agent-backlist-monthly .input-filter {
      display: inline-block;
    }
    .agent-backlist-monthly .input-filter .input-item, .agent-backlist-monthly .input-filter .input-item.short {
      width: 90%;
      margin:  0;
    }
  
    .agent-backlist-monthly .input-filter .input-item.button {
      width: 90%;
    }
  
    .agent-backlist-monthly .input-filter .input-item .btn{
      margin: 0;
      margin-right: 16px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .agent-backlist-monthly .card-table {
      display: inline-block;
      overflow-x: auto;
    }
    .ant-table-wrapper {
      margin-right: 2.5%;
    }
  }
  