.message {
  width: 100%;
  height: 100%;
}

.message .card-table {
  display: inline-block;
  justify-content: space-around;
  width: 100%;
  min-height: 180px;
  padding: 16px;
  background: #FFFFFF;
  transition: all .3s;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  border-radius: 4px;
}

.message .input-filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 16px;
}

.message .input-filter .input-item {
  width: 236px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 16px;
  padding-bottom: 16px;
}

.message .input-filter .input-item.button {
  width: 300px;
}

.message .input-filter .input-item.short {
  width: 180px;
} 

.message .input-filter .input-item .text {
  flex: 1;
  font-weight: bold;
}

.message .input-filter .input-item .btn{
  margin-right: 16px;
  display: inline-block;
}

.message .input-filter .input-item .btn .ant-btn{
  margin-right: 16px;
}

.message .export {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 4px;
}

@media only screen and (max-width: 1300px){
  .message .input-filter .input-item {
    width: 32%;
  }
  .message .input-filter .input-item.short {
    width: 24%;
  }
  .message .input-filter .input-item.button {
    width: 40%;
  }
}

@media only screen and (max-width: 992px){
  .message .input-filter {
    margin-left: 28px;
  }
}

@media only screen and (max-width: 729px) {
  .message .input-filter {
    display: inline-block;
  }
  .message .input-filter .input-item, .message .input-filter .input-item.short {
    width: 90%;
    margin:  0;
  }

  .message .input-filter .input-item.button {
    width: 90%;
  }

  .message .input-filter .input-item .btn{
    margin: 0;
    margin-right: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .message .card-table {
    display: inline-block;
    overflow-x: auto;
  }
  .ant-table-wrapper {
    margin-right: 2.5%;
  }
}
