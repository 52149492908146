.logo {
  position: absolute;
  display: inline-block;
  width: 150px;
  height: 75px;
  margin-top: -7px;
  /* margin: 16px 0%; */
  /* background: rgba(255, 255, 255, 0.3); */
  background-image: url(./MTL_LOGO.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
}

.logout {
  width: 120px;
  height: 31px;
  color: #FFFFFF;
  margin: 16px 0;
  float: right;
}

.logout .icon {
  color: #FFFFFF;
}

.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left {
  background: rgba(0, 21, 42, 0.8);
  color: #FFFFFF;
}

.header-bar {
  background: #FFFFFF !important;
  padding: 0 16px !important;
  border-bottom: 1px solid #CCCCCC !important;
}

.header-menu {
  line-height: 63px !important;
  float: right !important;
}

.menu-item-logout {
  border-bottom: 1px solid #CCCCCC !important;
  box-shadow: none !important;
}

.menu-item-logout:hover, .menu-item-logout.ant-menu-item-selected:hover{
  height: 62px !important;
  line-height: 58px !important;
  border-bottom: 2px solid #e6007e !important;
}

.menu-item-logout.ant-menu-item-selected {
  border-bottom: 1px solid #e6007e !important;
}

.menu-username {
  padding-right: 8px !important;
  line-height: 63px !important;
  float: right !important;
}

.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left {
  background: #e6007e !important;
}


.layout-content {
  /* background: url('./CELEBRATION-PATTERN-CROP.png') right bottom no-repeat;
  background-position: right bottom; 
  background-repeat: no-repeat;  */
}

.layout-footer {
  text-align: center;
  background: rgba(0, 0, 0, 0) !important;
}