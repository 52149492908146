.richmenu-form {
  width: 100%;
  min-height: 700px;
  padding: 16px;
  background: #FFFFFF;
  transition: all .3s;
  -webkit-box-shadow: 0 2px 0 rgba(190, 164, 164, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  border-radius: 4px;
}

.rich-preview{
  width: 100%;
  padding-top: 67.5%;
  position: relative; 
  /* border: 1px dashed #CCCCCC; */
  margin-bottom: 16px;
}

.rich-preview.compact{
  padding-top: 33.75%; 
}

.rich-preview .image{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.rich-preview .table-layout {
  display: flex;
  flex-flow: column;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  border: 2px solid #e6007e;
}

.rich-preview .table-layout .row {
  width: 100%;
  height: 50%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.rich-preview .table-layout .row.compact {
  height: 100%;
}

.rich-preview .table-layout .row .col {
  width: inherit;
  border: 2px solid #e6007e;
  position: relative;
}

.rich-preview .table-layout .row .col p{
  position: absolute;
  width: 34px;
  height: 34px;
  left: 50%;
  margin-left: -17px;
  top: 50%;
  margin-top: -17px;
  font-weight: bold;
  font-size: 2.4em;
  color: #FFF;
  text-shadow: -1px 0 #333, 0 1px #333, 1px 0 #333, 0 -1px #333;
}

.rich-preview .icon{
  position: absolute;
  width: 34px;
  height: 34px;
  left: 50%;
  margin-left: -17px;
  top: 50%;
  margin-top: -17px;
  font-weight: bold;
  font-size: 2.4em;
  color: #AAAAAA;
}

.richmenu-form .form .richmenu-layout {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.richmenu-form .form .richmenu-layout .richmenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 8px;
  margin-right: 16px;
  margin-bottom: 16px;
  border: 1px solid #FEFEFE;
  transition: all .3s;
  text-align: center;
  border: 1px solid rgba(180, 180, 180, 0.4);
  cursor: pointer;
}


.richmenu-form .form .richmenu-layout .richmenu:hover {
  -webkit-box-shadow: 0px 0px 4px 2px rgba(173, 173, 173, 0.6);
  -moz-box-shadow: 0px 0px 4px 2px rgba(173, 173, 173, 0.6);
  box-shadow: 0px 0px 4px 2px rgba(173, 173, 173, 0.6);
}

.richmenu-form .form .richmenu-layout .richmenu img {
  margin-bottom: 8px;
}

.collapes-action {
  margin-bottom: 8px;
}

.btn-group-upload {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.btn-group-upload button, .btn-group-upload .ant-upload {
  margin-right: 4px;
}