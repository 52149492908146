@font-face {
  font-family: Ubuntu;
  src: url('/fonts/Ubuntu/Ubuntu-Regular.ttf');
}

@font-face {
  font-family: Ubuntu;
  src: url('/fonts/Ubuntu/Ubuntu-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: Ubuntu;
  src: url('/fonts/Ubuntu/Ubuntu-Italic.ttf');
  font-style: italic;
}

@font-face {
  font-family: Kalatexa;
  src: url('/fonts/Kalatexa/kalatexatext_bold.eot'),
       url('/fonts/KalaTeXa/kalatexatext_bold.woff2') format("woff2"),
       url('/fonts/KalaTeXa/kalatexatext_bold.woff') format("woff");
}

@font-face {
  font-family: Kalatexa;
  src: url('/fonts/Kalatexa/kalatexatext_bold.eot'),
       url('/fonts/KalaTeXa/kalatexatext_bold.woff2') format("woff2"),
       url('/fonts/KalaTeXa/kalatexatext_bold.woff') format("woff");
  font-weight: bold;
}

body {
  margin: 0;
  font-family: 'Ubuntu', 'Kalatexa', sans-serif;
  -webkit-font-smoothing: antialiased;
  /* -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* red mtl */
.btn-custom-red {
  /* color: rgba(192, 13, 13, 0.7) !important;
  border-color: rgba(192, 13, 13, 0.7) !important; */
}

.btn-custom-red:hover {
  /* color: rgb(192, 13, 13) !important;
  border-color: rgba(192, 13, 13) !important; */
}

.btn-custom-red:disabled, .btn-custom-red[disabled] {
  color: #cccccc !important;
  border-color: #cccccc !important;
} 

/* export blue */
.btn-custom-blue {
  color: rgb(0, 132, 197) !important;
  border-color: rgb(0, 132, 197) !important;
}

.btn-custom-blue:hover,
.btn-custom-blue[hover] {
  color: rgba(0, 142, 207, 0.8) !important;
  border-color: rgba(0, 142, 207, 0.8) !important;
}

.btn-custom-blue:disabled,
.btn-custom-blue[disabled] {
  color: #cccccc !important;
  border-color: #cccccc !important;
} 

/* export mtl */
.btn-custom-export:hover,
.btn-custom-export[hover] {
  color: rgb(114, 187, 111) !important;
  border-color: rgb(114, 187, 111) !important;
}

/* green */
.btn-custom-green {
  color: rgb(114, 187, 111) !important;
  border-color: rgb(114, 187, 111) !important;
}

.btn-custom-green:hover,
.btn-custom-green[hover] {
  color: rgba(114, 187, 111, 0.8) !important;
  border-color: rgba(114, 187, 111, 0.8) !important;
}

.btn-custom-green:disabled,
.btn-custom-green[disabled] {
  color: #cccccc !important;
  border-color: #cccccc !important;
} 

/* darkgreen */
.btn-custom-darkgreen {
  color: rgb(0, 96, 46, 0.8) !important;
  border-color: rgb(0, 96, 46, 0.8) !important;
}

.btn-custom-darkgreen:hover,
.btn-custom-darkgreen[hover] {
  color: rgba(0, 96, 46, 0.7) !important;
  border-color: rgba(0, 96, 46, 0.7) !important;
}

.btn-custom-darkgreen:disabled,
.btn-custom-darkgreen[disabled] {
  color: #cccccc !important;
  border-color: #cccccc !important;
} 

/* darkblue mtl */
.btn-custom-darkblue {
  color: rgb(47, 40, 112) !important;
  border-color: rgb(47, 40, 112) !important;
}

.btn-custom-darkblue :hover {
  color: rgba(47, 40, 112, 0.548) !important;
  border-color: rgba(47, 40, 112, 0.432) !important;
}

.btn-custom-darkblue :disabled, .btn-custom-darkblue[disabled] {
  color: #cccccc !important;
  border-color: #cccccc !important;
} 


