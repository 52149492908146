.pagenotfound {
  width: 100wh;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagenotfound h1 {
  font-size: 3em;
  color: #CCC;
}
