.product-form {
  display: inline-block;
  width: 100%;
  min-height: 180px;
  padding: 16px;
  background: #FFFFFF;
  transition: all .3s;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  border-radius: 4px;
}

.product-form .form {
  max-width: 650px;
}

.image-preview {
  display: inline-block;
  min-height: 240px;
  min-width: 350px;
  max-height: 100%;
  max-width: inherit;
  border: 1px dashed #CCCCCC;
  font-size: 3em;
  color: #CCCCCC;
  background: #FFFFFF;
  overflow: auto;
}

#imagePreview, #imagePreviewUpdate {
  margin: 5%;;
  width: 90%;
  height: 90%;
}
.anticon-file-image {
  margin-top: 100px;
}